<template>
  <q-step
    :name="5"
    title="3rd-party tools"
    icon="add_comment"
    caption="Optional"
    :done="this.update_step > 5"
  >
    <div class="row" style="margin-top: 10px">
      <q-toggle
        :model-value="install_knative"
        @update:model-value="$emit('install_knative', $event)"
        label="Install knative"
        class="col-2"
      />
      <q-toggle
        :model-value="install_nfs"
        @update:model-value="$emit('install_nfs', $event)"
        label="Install NFS"
        class="col-2"
      />
      <q-toggle
        :model-value="install_harbor"
        label="Install Harbor"
        @update:model-value="$emit('install_harbor', $event)"
        class="col-2"
      />
      <q-toggle
        v-if="kubernetes_flavour === 'bcm' && installation_type.toLowerCase() === 'saas'"
        :model-value="cluster_installer_enabled"
        label="Cluster installer enabled"
        @update:model-value="$emit('cluster_installer_enabled', $event)"
        class="col-2"
      />
    </div>

    <div class="row" style="margin-top: 10px">
      <q-toggle
          class="col-2"
          :model-value="ingressController.enabled"
          @update:model-value="updateIngressController('enabled', $event)"
          label="Enable Ingress Controller"
      />
      <q-toggle
        :disable="!kubernetes_version || +kubernetes_version?.slice(0,4) > 1.25"
        :model-value="install_kubeflow"
        label="Install kubeflow"
        @update:model-value="$emit('install_kubeflow', $event)"
        class="col-2"
      />
      <q-toggle
        class="col-2"
        :disable="install_kubeflow"
        :model-value="install_training_operator"
        @update:model-value="$emit('install_training_operator', $event)"
        label="Install training-operator"
      />
      <q-toggle
        class="col-2"
        :model-value="enable_kwok"
        @update:model-value="$emit('enable_kwok', $event)"
        label="Enable Kwok"
      />
    </div>
    <div class="row" style="margin-top: 10px">
      <transition name="slide-fade">
        <div v-if="ingressController.enabled" class="x">
          <q-select
              stack-label
              label="ingress controller type (Optional)"
              :model-value="ingressController.type"
              @update:model-value="updateIngressController('type', $event)"
              :options="ingressControllerTypeOptions"
              style="margin-bottom: 10px; width: 215px"
          />
          <!-- Show comment when 'istio' is selected -->
          <div v-if="ingressController.type === 'istio'" class="text-bold text-lg text-red">
            <q-icon name="info" /> only v2.19+
          </div>
        </div>
      </transition>
      <transition name="slide-fade">
        <div v-if="enable_kwok" class="x">
          <q-input
              stack-label
              label="kwok ratio"
              :model-value="kwok_ratio"
              @update:model-value="$emit('kwok_ratio', $event)"
              style="margin-bottom: 10px; width: 100px; margin-left: 20px"
          />
        </div>
      </transition>
    </div>
    <div class="row" style="margin-top: 10px">
      <q-toggle
          class="col-2"
          :model-value="remote_telemetry.enabled"
          @update:model-value="updateRemoteTelemetry('enabled', $event)"
          label="Enable Remote Telemetry"
      />
    </div>
    <transition name="slide-fade">
      <div v-if="remote_telemetry.enabled" class="x">
        <div class="row" style="margin-top: 10px">
          <q-toggle
              class="col-2"
              :model-value="remote_telemetry.metrics"
              @update:model-value="updateRemoteTelemetry('metrics', $event)"
              label="Enable Metrics"
          />
          <q-toggle
              class="col-2"
              :model-value="remote_telemetry.logs"
              @update:model-value="updateRemoteTelemetry('logs', $event)"
              label="Enable Logs"
          />
          <q-toggle
              class="col-2"
              :model-value="remote_telemetry.traces"
              @update:model-value="updateRemoteTelemetry('traces', $event)"
              label="Enable Traces"
          />
        </div>
      </div>
    </transition>
    <q-stepper-navigation>
      <q-btn
        flat
        @click="$emit('update_step', 4)"
        color="primary"
        label="Back"
        class="q-ml-sm"
      />
      <q-btn
        color="primary"
        label="Finish"
        @click="$emit('yaml_dialog_state', true)"
      />
    </q-stepper-navigation>
  </q-step>
</template>

<script>
export default {
  watch: {
    // Watch for changes to kubernetes_flavour and reset ingressController.type
    kubernetes_flavour() {
      this.resetIngressControllerType();
    }
  },
  methods: {
    updateIngressController(key, value) {
      this.$emit('updateIngressController', { ...this.ingressController, [key]: value });
    },
    updateRemoteTelemetry(key, value) {
      this.$emit('updateRemoteTelemetry', { ...this.remote_telemetry, [key]: value });
    },
    // Reset ingressController.type when kubernetes_flavour changes
    resetIngressControllerType() {
      this.updateIngressController('type', '');  // Clear the selection
    },
  },
  computed: {
    // Dynamically update the ingress controller options based on the kubernetes_flavour
    ingressControllerTypeOptions() {
      if (
        this.kubernetes_flavour === "aws" ||
        this.kubernetes_flavour === "gcp" ||
        this.kubernetes_flavour === "aks" ||
        this.kubernetes_flavour === "op" ||
        this.kubernetes_flavour === "rke"
      ) {
        return ["nginx", "istio"];
      } else if (this.kubernetes_flavour === "rke2") {
        return ["nginx", "rke2", "istio"];
      }
      return ["nginx"];
    },
  },
  props: {
    kubernetes_flavour: {
      type: String,
      required: true,
    },
    installation_type: {
      type: String,
      required: true,
    },
    cluster_installer_enabled: {
      type: Boolean,
      required: true,
    },
    kubernetes_version: {
      type: String,
      required: true,
    },
    update_step: {
      type: Number,
      required: true,
    },
    install_training_operator: {
      type: Boolean,
      required: true,
    },
    ingressController: {
      type: Object,
      required: true,
    },
    enable_kwok: {
      type: Boolean,
      required: true,
    },
    install_kubeflow: {
      type: Boolean,
      required: true,
    },
    install_mpi_operator: {
      type: Boolean,
      required: true,
    },
    install_nfs: {
      type: Boolean,
      required: true,
    },
    install_knative: {
      type: Boolean,
      required: true,
    },
    install_harbor: {
      type: Boolean,
      required: true,
    },
    kwok_ratio: {
      type: Number,
      required: true,
    },
    enable_multi_tenancy: {
      type: Boolean,
      required: true,
    },
    remote_telemetry: {
      type: Object,
      required: true,
    }
  },
};
</script>


